<template>
  <section>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="block my-buttons">
            <div class="danger-zone">
              <h4>Danger Zone</h4>

              <div class="danger-block top">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <h6>
                      Move Out
                    </h6>
                    <p v-if="!dashboard.move_out">
                      Notify us of your intention to move out
                    </p>
                    <p v-else class="unavailable">
                      You have notified us of your move out date
                    </p>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="move-out">
                      <b-button
                        v-bind:class="{ disabled: dashboard.move_out }"
                        @click="requestMoveOut"
                        variant="success"
                        :disabled="isMovedOut"
                        >Move Out</b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PrePayDangerZone",
  props: {
    id: {
      type: String,
      required: true
    },
    dashboard: {
      type: Object,
      required: true
    }
  },
  computed: {
    isMovedOut() {
      return !!this.dashboard.move_out;
    }
  },
  methods: {
    refreshData() {
      this.$emit("refresh");
    },
    requestMoveOut() {
      if (this.isMovedOut) {
        return;
      }

      this.$swal({
        title: "Notify Move Out",
        icon: "info",
        input: "text",
        html:
          "<div><p>Please enter the move out date below</p></div>" +
          '<input id="swal-input1" class="swal2-input" type="date" placeholder="Date">' +
          '<div id="swal-error"></div>',
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        customClass: {
          input: "d-none"
        },
        preConfirm: () => {
          const date = document.getElementById("swal-input1").value;

          const id = this.id;
          const data = {
            date: date
          };

          return this.$store
            .dispatch("dashboardPrePayMoveOut", { id, data })
            .then(() => {
              const title = "Move Out Requested";
              const subtitle = "We have received your move out notification";
              this.$store.dispatch("pushSuccess", { title, subtitle });

              this.refreshData();
            })
            .catch(err => {
              const title = "Invalid data";
              const subtitle = "There was a problem with your request: " + err;
              const samePage = true;
              this.$store.dispatch("pushError", { title, subtitle, samePage });
            })
            .finally(() => {
              this.submitting = false;
            });
        },
        inputValidator: () => {
          if (!document.getElementById("swal-input1").value) {
            return "Please enter a move out date";
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/scss/dashboard";

.disabled {
  cursor: not-allowed;
}

.unavailable {
  font-style: italic;
  font-size: 0.9rem;
}

.move-out {
  text-align: right;
}
</style>
